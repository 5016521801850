<template>
  <div class="page">
    <div class="title">岗位管理</div>

    <!-- filter -->
    <div class="filter_box">
      <div class="filter_search">
        <!-- input -->
        <el-input
          class="filter_search_input"
          placeholder="请输入关键词"
          v-model="search.keyword"
          clearable
        >
        </el-input>
        <div class="custom_button no_select" @click="toSearch">搜索</div>
        <div class="custom_button plain no_select" @click="add">添加岗位</div>
      </div>
    </div>

    <!-- table -->
    <el-table
      id="table"
      :data="tableData"
      :header-cell-style="tableHeaderStyle"
      :cell-style="tableCellStyle"
      style="width: 100%"
    >
      <el-table-column prop="name" label="岗位名称"></el-table-column>
      <!-- <el-table-column
                prop="create_time"
                label="创建时间"
            ></el-table-column>
            <el-table-column
                prop="update_time"
                label="修改时间"
            ></el-table-column> -->
      <el-table-column label="操作" min-width="180" fixed="right">
        <template v-slot:default="scope">
          <div class="operations">
            <div>
              <div @click="edit(scope.row)">编辑</div>
              <div @click="del(scope.row)">删除</div>
            </div>
          </div>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      layout="prev, pager, next"
      :total="page.total"
      :page-size="page.size"
      :current-page.sync="page.page"
      @current-change="loadData"
    >
    </el-pagination>
  </div>
</template>

<script>
import { mapActions } from "vuex";

export default {
  inject: ["reload"],
  data() {
    return {
      search: {
        keyword: "",
      },
      tableData: [],
      page: {
        size: 10,
        page: 1,
        total: 0,
      },

      tableHeaderStyle: {
        backgroundColor: "#F5F8FA",
        textAlign: "center",
      },
      tableCellStyle: {
        textAlign: "center",
      },
    };
  },
  created() {
    this.loadData = this.loadDataFactory();
    this.loadData();
  },
  methods: {
    ...mapActions("enterprise", [
      "getStationList",
      "addStation",
      "editStation",
      "delDepartment",
    ]),

    loadDataFactory(keyword = null) {
      let params = {};
      if (keyword) {
        params.keywords = keyword;
      }
      return () => {
        params.page = this.page.page;
        params.size = this.page.size;
        let loading = this.$loading();
        this.getStationList(params)
          .then((res) => {
            console.log(res);
            this.tableData = res.data.data.list.data;
            this.page.total = res.data.data.list.total;
            this.$nextTick(() => {
              loading.close();
            });
          })
          .catch((e) => {
            this.$message.error(e.msg);
            loading.close();
          });
      };
    },

    /**
     * 搜索
     */
    toSearch() {
      this.page.page = 1;
      // 生产带有搜索参数的函数
      this.loadData = this.loadDataFactory(this.search.keyword);
      this.loadData();
    },

    add() {
      this.$prompt("请输入岗位名称", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        inputPattern: /.+/,
        inputErrorMessage: "请输入岗位名称",
      }).then(({ value }) => {
        this.addStation({
          name: value,
        })
          .then((res) => {
            console.log(res);
            this.$message.success(res.data.msg);
            this.loadData();
          })
          .catch((e) => {
            console.error(e);
            this.$message.error(e.msg);
          });
      });
    },

    edit(row) {
      this.$prompt("请输入岗位名称", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        inputPattern: /.+/,
        inputErrorMessage: "请输入岗位名称",
        inputValue: row.name,
      }).then(({ value }) => {
        this.editStation({
          id: row.id,
          name: value,
        })
          .then((res) => {
            console.log(res);
            this.$message.success(res.data.msg);
            this.loadData();
          })
          .catch((e) => {
            console.error(e);
            this.$message.error(e.msg);
          });
      });
    },

    del(row) {
      this.$confirm("此操作将永久删除, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        this.delDepartment({
          id: row.id,
        })
          .then((res) => {
            console.log(res);
            this.$message({
              type: "success",
              message: res.data.msg,
            });
            this.loadData();
          })
          .catch((e) => {
            console.error(e);
            this.$message({
              type: "error",
              message: e.msg,
            });
            this.loadData();
          });
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.page {
  position: relative;
  padding-bottom: 50px;
  min-height: calc(100% - 50px);
}

.title {
  font-size: 14px;
  font-weight: bold;
  color: #1c2438;
}

.filter_box {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 30px 0;

  .el-date-editor {
    height: 36px;
    margin-right: 10px;

    ::v-deep .el-input__inner {
      height: 36px;
    }
  }

  .filter_search {
    display: flex;
    align-items: center;

    .filter_search_select {
      width: 80px;
      height: 36px;
      margin-right: 10px;

      ::v-deep .el-input__inner {
        height: 36px;
      }

      ::v-deep .el-input__icon {
        line-height: 36px;
      }
    }

    .filter_search_input {
      width: 350px;
      height: 36px;
      margin-right: 10px;

      ::v-deep .el-input__inner {
        height: 36px;
      }
    }
    > div:not(:first-child) {
      margin-left: 20px;
    }
  }

  .filter_btns {
    display: flex;
    align-items: center;

    > div:not(:first-child) {
      margin-left: 20px;
    }
  }
}

.operations {
  > div {
    display: flex;
    justify-content: center;
    align-items: center;

    font-size: 14px;
    color: #406bff;
    white-space: nowrap;
    cursor: pointer;

    &:not(:last-child) {
      margin-bottom: 10px;
    }

    > div {
      cursor: pointer;

      &:not(:last-child) {
        margin-right: 10px;
      }
    }
  }
}
</style>
